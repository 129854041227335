import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import HeaderGame from './components/HeaderGame'
import HistoryCards from './components/HistoryCards'
import CardSinging from './components/CardSinging'
import GameType from './components/GameType'
import CurrentGameHeader from './components/CurrentGameHeader'

import Winner from './components/Winner'
import PlayerSearch from './components/PlayerSearch'
import {getGame, getCardsHistory, getWinnersInfo, newGameAction, undoLastCard, newCardSignPublish, acceptWinners} from './redux/actions/ActionGame'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router'
import Webcam from 'react-webcam'


export class App extends Component {
   constructor(props){
      super(props)
   }
  state = {
    currentState:0, 
    idPartida:0,
    idJuego:0, 
    conteoJuegoActual:0,
    totalJuegos:0,
    idTipoJuego:0,
    newCard:null,
    buscadorUI:false, 
    winner:false,
    newGame: false,
    validUser: true, 
    undoCard:null, 
    newGameReloaded:false,
    idGame : 2
  }
componentWillMount(){
   //this.props.getGame(this.props.match.params.idGame);
    this.props.getGame(this.state.idGame);
}
  // Cada carta que es emitida que es ingresada es reportada para que se agregue al historico
  newCardEventHanlder = (cartaNueva) => {
     console.log('cartaNueva: ', cartaNueva);
      this.setState({newCard:cartaNueva});   
      //if(cartaNueva.idCarta == 16){
      if(this.state.idJuego){
         if(this.state.idJuego != 0){
         this.props.getWinnersInfo(this.state.idJuego)
         } 
      }
         
         //this.props.getWinnersInfo(this.state.idJuego);
      //}

  }
  onSearchEventHandler= () => {
    console.log("BUSCAR");
    this.setState({
        buscadorUI : true,
        newCard:null
      });
  }
  newGameEventHandler = () => {
      // Solicitar Accion de nuevo juego 
      //this.props.newGameAction(1);
      
     
      this.setState({
        winner:false,
        newGameReloaded:true,
        newCard:null
      
      })
      
      this.props.acceptWinners(this.props.data.game.idjuego, this.state.idGame, this.excecuteNewGame)
      //this.props.acceptWinners(this.props.data.game.idjuego, this.props.match.params.idGame, this.excecuteNewGame)
      console.log('Aceptar GANADORES EVENTO CLICK >> ', this.props.data.game.idjuego);
      

  }
  excecuteNewGame = () => {
   if(this.state.newGameReloaded===true){
      this.setState({newGameReloaded:false, newCard:null, winner:false, undoCard:null})
      this.props.getGame(this.state.idGame);
      //this.props.getGame(this.props.match.params.idGame);
   }
}
  onSearchCloseEventHandler = () => {
      this.setState({
        buscadorUI : false
      });
  }
 
  onUndoEventHandler= () => {
      this.props.undoLastCard(this.props.data.game.idjuego)
      this.setState(
        {
            winner:false
        }
      )
      this.setState({newCard:null});   
      this.props.getWinnersInfo(this.props.data.game.idjuego)
      this.props.newCardSignPublish(0, 0)
  }
  onRedoEventHandler= () => {
      console.log("REHACER");
      //this.props.getGame();
  }
  


  render (){     

   
      if(this.props.data.game){
         if(this.props.data.undoLastCardAction){
            if(this.state.undoCard){
               if(this.state.undoCard.idcarta != this.props.data.undoLastCardAction.idcarta){
                  this.setState({undoCard :this.props.data.undoLastCardAction}) 
               }
            }else{
               this.setState({undoCard :this.props.data.undoLastCardAction}) 
            }
         }
      }
   

     if(this.props.data == null){
        return;
     }
      let winner = false
      let searchUIComponent = null;
      
      if(this.props.data.winnersInfo ){
         if(this.props.data.winnersInfo.length > 0){
            winner  = true;
         }
      }
      if(this.state.buscadorUI === true){
            searchUIComponent = () => {
               return(
                  <PlayerSearch/>
               )
            }
      }


      
 

       
      if(this.props.data.game){
         if(this.props.data.game.idjuego){            
            if(this.state.idJuego != this.props.data.game.idjuego){
               
               this.setState ({idJuego:this.props.data.game.idjuego})
               this.props.getWinnersInfo(this.props.data.game.idjuego);
               // el juego cambio 
            }
         }
      }



      return (
         <div className="primaryContainer clearfix">
               {this.state.validUser===false?<Redirect to="/"/>:null}
               <div id="videoGame" className="clearfix">
                  <div style={{width: '100%', height: '100%'}}>
                     <div className="clearfix, VideoDisplayStyle">

                     <Webcam audio={false} screenshotWidth={1080} videoConstraints={{ facingMode: "environment", width: 1920, height: 1080 }} ref={this.webcam} />
                     {/*<video id="video" width={640} height={480} autoPlay />*/}
                     </div>
                     <img id="image" src="img/ComposicionLogoFondoMorado.png" className="image, logoPrincipal" />
                     <div className="clearfix, GamType">
                        if(this.props.data.game){
                              
                              <GameType tipoJuego={this.props.data.game}/>
                          } 
                     </div>
                  </div>
               </div>
               <div className="mainFocus">
                  <div className="clearfix, HeaderJuego">
                     <CurrentGameHeader gameInfo={this.props.data.game} />
                  </div>
                  <div id className="clearfix, tablero">
                     <div id="UIInteraction" className="clearfix">
                     {/* ----------- CANTADA ACTUAL ------------------ */}
                     <CardSinging gameInfo={this.props.data.game}  onSearchEvent={this.onSearchEventHandler}
                                   onUndoEvent ={this.onUndoEventHandler}
                                   onRedoEvent={this.onRedoEventHandler}  
                                   onNewCardEvent={this.newCardEventHanlder}></CardSinging>
                     {/* ************************************************ */}
                     <div className="seccionesDiv">
                        
                        {this.state.buscadorUI === true ? <PlayerSearch idPlay={this.props.data.game.idjuego} onCloseSearchEvent={this.onSearchCloseEventHandler}/> : null}
                        
                        {winner === true ? <Winner winnersInfo={this.props.data.winnersInfo} onNewGameEvent={this.newGameEventHandler} onUndoEvent={this.onUndoEventHandler}/> : null}
                        
                     </div>
                     </div>
                     <HistoryCards undoCard={this.state.undoCard} newCard={this.state.newCard} gameInfo={this.props.data.game}></HistoryCards>
                  </div>
               </div>       
               <div className="EnVivo">
                  <span className="EnViVoText">· EN VIVO</span>
               </div>
         </div>      
         )
   }
}
 const mapStateToProps = (state) => {
    if(state.game.acceptWinnersAction){
     
      state.game.acceptWinnersAction.functionCallbak()
    }
    if(state.game){
       return {
         data: state.game
       }
    }
 }
 /*return {
const  mapDispatchToProps = (dispatch, ownProps) => {
      getCurrentGame:()=>{
         dispatch(getGame())
      }
   }
}*/
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators({getGame,getCardsHistory, getWinnersInfo, newGameAction, undoLastCard, newCardSignPublish, acceptWinners}, dispatch)
}

 export default connect(mapStateToProps, mapDispatchToProps)(App);
